import { useMutation, useQueryClient } from 'react-query';

// Local modules
import { putNotesApi } from 'services/HostalabServices';

// Hooks
import { QUERY_KEY as JOBS_QUERY_KEY } from 'hooks/useGetJobs';

const useSetNotes = () => {
	const queryClient = useQueryClient();
	
	return useMutation({
		mutationFn: (data) => {
			return putNotesApi({id: data.id, notes: data.notes});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: [JOBS_QUERY_KEY] })
		},
	});
};

export default useSetNotes;