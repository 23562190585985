import { DateTime } from 'luxon';

export const defaultDateFormat = (date) => (
  DateTime.fromJSDate(new Date(date), {zone: "America/New_York"}).toFormat('LL/dd/yyyy HH:mm')
);

export const defaultTimeFormat = (date) => (
  DateTime.fromJSDate(new Date(date), {zone: "America/New_York"}).toFormat('HH:mm')
);

export const formatDuration = (duration) => (duration?.split('.')?.[0]);
