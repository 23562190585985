import axios from "axios";
import API from "../api";
import {getCurrentUser} from "../ApiHelpers/UserPool";
import {useAuthStore} from "stores/auth";

const axiosInstance = axios.create({baseURL: `${API.backendApi}/`});

const getHeader = () => ({ "Content-Type": "application/json" });
export function createRequestFunc(methods, url, body, header) {
  let config = {
    method: methods,
    url,
    headers: getHeader(),
    data: body,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

axiosInstance.interceptors.request.use(
  async config => {
    config.headers['Authorization'] = `${(useAuthStore.getState().token)}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
