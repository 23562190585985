import React from "react";
import SideComponentImage from "../../assets/images/login-bg.svg";
import BrandIcon from "../../assets/images/login-hosta-icon.svg";
import "./SideComponent.scss";
const SideComponent = () => {
  return (
    <div className="login__screenWrapper">
      <img
        src={SideComponentImage}
        alt="auth-sidecomponent-image"
        className="side__image"
      />
      <div className="inner__content">
        <div className="logo-icon mb-4">
          <img
            src={BrandIcon}
            alt="brand-icon"
            className="img-fluid right-icon"
          />
        </div>
        <div className="content-brand text-center mt-3">
          <h4>Welcome to Hosta a.i.</h4>
          <div className="description">
            <p className="px-md-5">
              With just a few photos, you can instantly see detailed structural
              and spatial measurements, conditions and risks, floor plans, CAD
              and 3D models and even an itemized bill of materials with quality
              classifications and quantities of everything within the space -
              without even going onsite.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideComponent;
