import { Accordion, Form, InputGroup } from "react-bootstrap";
import "./InputComponent.scss";
import { useEffect, useState } from "react";
export const InputComponent = ({
  eventKey,
  title,
  name,
  handleInput,
  filter,
  remove,
}) => {

  const [val, setVal] = useState('');

  useEffect(() => {
    const value = filter.filter((fil) => fil.Type === name).length > 0
      ? filter.filter((fil) => fil.Type === name)[0].value
      : ""
    setVal(value);
  }, [filter])

  const handleSubmit = () => {
    handleInput({ target: { value: val, name } })
  }

  const handleChange = (evt) => {
    setVal(evt.target.value);
  }

  const handleClear = () => {
    handleInput({ target: { value: '', name } })
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      {filter.filter((fil) => fil.Type === name).length > 0 ? (
        <Accordion.Header>
          <p className="hoverTitle">{title}</p>
        </Accordion.Header>
      ) : (
        <Accordion.Header>{title}</Accordion.Header>
      )}
      <Accordion.Body>
        <span className="mb-3 search_input">
          <input
            aria-label={name}
            aria-describedby="inputGroup-sizing-default"
            placeholder={title}
            className="filter__Input"
            name={name}
            value={val}
            onChange={handleChange}
          />
          <span
            id="input-icon"
            className="d-flex align-items-center pe-4"
            onClick={handleClear}
          ></span>
          <span
            id="submit-icon"
            className="d-flex align-items-center pe-2"
            onClick={handleSubmit}
          ></span>
        </span>
      </Accordion.Body>
    </Accordion.Item>
  );
};
