import {useCopyToClipboard} from 'usehooks-ts';

const UUIDLink = ({uuid, link }) => {
  const [, copy] = useCopyToClipboard();

  const copyToClipBoard = async () => {
    await copy(uuid);
  };

  // const firstDigits = uuid?.split('-')?.[0] || '';

  return(
    <>
      <a
        className="me-3 d-inline-block"
        rel="noreferrer"
        target='_blank'
        href={link}
        style={{width: 200, wordBreak: 'break-word'}}
      >
        {uuid}
      </a>
      <a role="button" onClick={copyToClipBoard} className="text-secondary">
        <i className="bi bi-copy" />
      </a>
    </>
  );
};

export default UUIDLink;