import { createRequestFunc } from "./AxiosInstance";

export const getJobsApi = async ({ queryKey }) => {
  console.log(queryKey)
  return await createRequestFunc("get", `jobs?${queryKey?.[1]?.params}`);
};

export const putPriorityApi = async (data) => {
  let apidata = {
    priority: data.priority,
    email: data.email,
  };
  return await createRequestFunc("put", `jobs/${data.id}`, apidata);
};

export const putNotesApi = async (data) => {
  return await createRequestFunc("put", `jobs/${data.id}`, {
    notes: data.notes,
  });
};

export const getFields = async () => {
  return await createRequestFunc("get", '/jobs/fields');
};

export const getFile = async (room_Id, force_update) => {
  return await createRequestFunc("get", `/room?room_id=${room_Id}&force_update=${force_update}`);
};

//Organisation API
export const getOganisations = async () => {
  return await createRequestFunc("get", `orgs`);
};

function objectToQueryString(params) {
  return Object.entries(params).filter(([key, value]) => value !== null && value !== undefined && value !== '')
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
}

//Claims API
export const getClaims = async (lastEvaluatedKey = null, filters) => {
  const queryString = objectToQueryString(filters);
  let otherParams = '';
  if (queryString)
    otherParams = lastEvaluatedKey ? `&${queryString}` : `?${queryString}`;
  if (lastEvaluatedKey)
    return await createRequestFunc("get", `claims?LastEvaluatedKey=${lastEvaluatedKey}${otherParams}`);
  return await createRequestFunc("get", `claims${otherParams}`);
};