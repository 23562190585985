import {useCallback, useEffect, useState} from 'react';

const buildFilterObject = (filters) => {
  return filters.reduce((acc, { type, value }) => {
    acc[type] = value;
    return acc;
  }, {});
};

const useFilters = () => {
  const [searchParams, setSearchParams] = useState(null);
  const [localFilters, setLocalFilters] = useState([]);

  useEffect(() => {
    if (localFilters.length) {
      const params = buildFilterObject(localFilters);
      const urlSearchParams = new URLSearchParams(params).toString();
      setSearchParams(urlSearchParams);
    } else {
      setSearchParams(null);
    }
  }, [localFilters]);

  const setFilters = useCallback((fltrs) => {
    setLocalFilters(fltrs);
  }, []);

  return { setFilters, searchParams, filters: localFilters };
};

export default useFilters;