import React, { useState, useRef } from "react";
import {
  Navbar,
  Nav,
  Container,
  Overlay,
  Popover,
  Button,
} from "react-bootstrap";
import "./NavbarComponent.scss";
import { useAuthStore } from 'stores/auth';

const NavbarComponent = () => {
  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);

  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleLogout = async () => {
    logout();
  };

  return (
    <React.Fragment>
      <Navbar>
        <Container>
          <Navbar.Brand href="#" className="user__name">
            Pipeline dashboard
          </Navbar.Brand>
          <Nav className="ms-auto d-flex align-items-center">
            <Nav.Link
              href="#"
              className="profile__section text-white d-flex align-items-center"
              ref={ref}
              onClick={handleClick}
            >
              <span className="mx-2">{user.email}</span>

              {/* {show && (              
                  <Dropdown.Item
                  style={{color:"white"}}
                    onClick={() => {
                      localStorage.removeItem("token");
                      navigate("/");
                    }}
                  >
                    Logout
                  </Dropdown.Item>              
            )} */}
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-profile">
          <Popover.Body>
            <Nav className="flex-column">
              <Nav.Link className="m-0" href="/login"></Nav.Link>
              <Button variant="danger" onClick={handleLogout}>
                {" "}
                Logout
              </Button>
              {/* <button
             className="m-0 py-2 d-flex align-items-left"
             onClick={() => {
              localStorage.removeItem("token");
              navigate("/");
             }}
             eventKey="link-2"
           >
             Logout
           </button> */}
            </Nav>
          </Popover.Body>
        </Popover>
      </Overlay>
    </React.Fragment>
  );
};
export default NavbarComponent;
