import Papa from 'papaparse';

const downloadCSV = (data, columns) => {
  const csv = Papa.unparse(data, { columns });
  const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  const csvURL = window.URL.createObjectURL(csvData);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('claims', 'claims.csv');
  tempLink.click();
};

export default downloadCSV;