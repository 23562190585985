const MustContainItem = (props) => {
  // eslint-disable-next-line react/prop-types
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  return (
    <div className="MustContainItem">
      <ul
        className={meetsReq === true ? "must-item list__success" : "must-item"}
      >
        <li
          className={
            meetsReq === true ? "mb-0 success" : "mb-0 validation-label"
          }
        >
          {label}
        </li>
      </ul>
    </div>
  );
};

export default MustContainItem;
