import {useQuery} from 'react-query';
import {useEffect} from 'react';

// Local modules
import {getJobsApi} from 'services/HostalabServices';

// Hooks
import {useJobStore} from 'stores/jobs';

export const QUERY_KEY = 'jobs';

const useGetJobs = (searchParams) => {
  const {jobData, daysBuffer, setJobs} = useJobStore();
  
  const sp = searchParams ? `&${searchParams}` : '';
  const params = `minResults=100&maxDays=${daysBuffer}${sp}`;
  const queryResult = useQuery({
    retry: 10,
    enabled: !searchParams,
    queryKey: [QUERY_KEY, {params}],
    queryFn: getJobsApi,
    refetchInterval: 60 * 1000, // re-fetch every 1 minute
    refetchIntervalInBackground: true,
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (params) {
      // re-fetch the query when filters applied.
      (async () => {
        await queryResult.refetch()
      })();
    }
  }, [params]);
  
  useEffect(() => {
    if (queryResult.status === 'success') {
      const data = !params.includes('LastEvaluatedKey')
        ? [ ...queryResult.data.jobs ]
        : [ ...jobData, ...queryResult.data.jobs ];
      
      setJobs({
        jobData: queryResult.data.jobs.length ? data : [],
        lastEvaluatedKey: queryResult.data.LastEvaluatedKey
      });
    }
  }, [ queryResult.status, queryResult.data]);

  return queryResult;
};

export default useGetJobs