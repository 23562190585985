import { useState, useCallback, useEffect } from 'react';

const useIsSticky = () => {
  const [ isSticky, setIsSticky ] = useState(false);
  const [ childNode, setChildNode ] = useState(null);
  const [ parentNode, setParentNode ] = useState(null);

  useEffect(() => {
    const calculateSticky = (left) => setIsSticky(parentNode?.scrollLeft >= left);

    if (parentNode && childNode) {
      const left = childNode?.getBoundingClientRect()?.left - 100;
      parentNode?.addEventListener('scroll', calculateSticky.bind(this, left));
    }

    return () => parentNode?.removeEventListener('scroll', calculateSticky);
  }, [parentNode, childNode]);

  const setParentRef = useCallback((node) => {
    setParentNode(node);
  }, []);

  const setChildRef = useCallback((node) => {
    if (!childNode && node) {
      setChildNode(node);
    }
  }, [childNode]);

  return { isSticky, setChildRef, setParentRef };
};

export default useIsSticky;