import validator from 'validator';
import { DateTime } from 'luxon';

const getDataType = (value) => {
  if (value === null || value === undefined || typeof value === 'object') {
    return null;
  }

  if (typeof value === 'number') {
    return 'number';
  }

  if (validator.isUUID(value)) {
    return 'UUID';
  } else if (DateTime.fromJSDate(new Date(value)).isValid) {
    return 'Date';
  }

  return 'unknown';
};

export default getDataType;