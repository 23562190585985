import { create } from 'zustand';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

import UserPool from 'utils/userPool';

const initialState = {
	user: null,
	token: null,
	error: null,
	signedUp: false,
	isAuthenticated: false,
};

export const signup = ({ email, password }) => {
	return new Promise((resolve, reject) => {
		UserPool.signUp(email, password, [], null, (err, result) => {
			if (err) {
				resolve({ status: false, message: err.message });
			} else {
				resolve({status: true});
			}
		});
	});
}

export const resetPasswordWithCode = ({ email, code, password })  => {
	return new Promise(resolve => {
		const user = new CognitoUser({ Username: email, Pool: UserPool });
		user.confirmPassword(code, password, {
			onSuccess: () => {
				resolve('success');
			},
			onFailure: err => {
				resolve(err.message);
			},
		});
	});
}

export const forgotPassword = ({ email }) => {
	return new Promise(resolve => {
		const user = new CognitoUser({ Username: email, Pool: UserPool });
		user.forgotPassword({
			onSuccess: () => {
				resolve('success');
			},
			onFailure: err => {
				resolve(err.message);
			},
			inputVerificationCode: () => {
				resolve('send');
			},
		});
	});
}

export const useAuthStore = create((set) => ({
	...initialState,
	authenticate: ({ password, email }) => {
		const user = new CognitoUser({ Username: email, Pool: UserPool });
		const credentials = new AuthenticationDetails({ Username: email, Password: password });
		user.authenticateUser(credentials, {
			onSuccess({ idToken }) {
				set((state) => ({
					...state,
					token: idToken.jwtToken,
					isAuthenticated: true,
					user: {
						email: idToken.payload.email,
						userType: idToken.payload['cognito:groups']?.[0],
					},
				}));
			},
			onFailure(err) {
				set((state) => ({ ...state, error: err, isAuthenticated: false }));
			},
		});
	},
	logout: () => {
		const user = UserPool.getCurrentUser();
		if (user) {
			user.signOut();
		}
		set((state) => ({ ...state, ...initialState }));
	},
	getSession: () => {
		const user = UserPool.getCurrentUser();
		user?.getSession((err, session) => {
			if (err) {
				set((state) => ({ ...state, ...initialState }));
				return;
			}
			
			const { idToken } = session;
			set((state) => ({
				...state,
				token: idToken.jwtToken,
				isAuthenticated: true,
				user: {
					email: idToken.payload.email,
					userType: idToken.payload['cognito:groups']?.[0],
				},
			}));
		});
	},
}));