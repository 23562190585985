import {useQuery} from 'react-query';

// Local modules
import {getOganisations} from 'services/HostalabServices';

export const QUERY_KEY = 'orgs';

const useGetOrgs = () => {
  return  useQuery({
    retry: 10,
    queryKey: QUERY_KEY,
    queryFn: () => getOganisations(),
    refetchOnReconnect: 'always',
  });
};

export default useGetOrgs