import classnames from "classnames";
import { TableVirtuoso } from 'react-virtuoso'

// Components
import UUIDLink from "components/UUIDLink";

// Utils
import getDataType from "utils/dataType";
import {getPrefix} from "utils/env";
import {defaultDateFormat, formatDuration} from "utils/date";

// Hooks
import useIsSticky from "hooks/useIsSticky";
import {useCopyToClipboard} from 'usehooks-ts';

// Assets
import EditIcon from 'assets/images/icons/icon-edit.svg';
import { getFile } from "services/HostalabServices";


const TableBody = (props) => {
	const {
		headings,
		jobData,
		isLoading,
		lastEvaluatedKey,
		setPriority,
		loadMore,
		handleComments
	} = props;
	const [, copy] = useCopyToClipboard();
	const { isSticky, setParentRef: stickyParentRef, setChildRef: stickyChildRef } = useIsSticky();
	
	const loadMoreRows = (range) => {
		if (jobData.length && (!isLoading && range.endIndex > (jobData.length / 2)) && (lastEvaluatedKey !== "" && lastEvaluatedKey !== null && lastEvaluatedKey !== "null")) {
			loadMore();
		}
	};
	
	const copyToClipBoard = async (info) => {
		await copy(info);
	};

	const handleDownload = (roomId, regenerate = false) => {
		getFile(roomId, regenerate).then((response) => {
			window.open(response.url, '_blank');
		}).catch((error) => {
			console.error(error);
		});
	};

	return (
		<TableVirtuoso
			style={{ height: '85vh' }}
			data={jobData}
			scrollerRef={stickyParentRef}
			components={{
				Table: ({ style, ...props }) => <table className="jobs-table" {...props} style={{ ...style }} />,
				TableHead: ({ style, ...props }) => <thead className="table__head" {...props} style={{ ...style }} />,
				EmptyPlaceholder: () => <tbody><td style={{ minWidth: 200 }}>No records were found!</td></tbody>
			}}
			fixedHeaderContent={() => {
				return (
					<tr>
						{headings.map(({name}, index) => {
							const isRoomID = name === 'roomId';
							return(
								<th
									className={classnames("table__head__column", {
										'table__body__stuck-column': isSticky && isRoomID,
										'table__body__sticky': isRoomID
									})}
									key={`t-headings-${index}`}
									{...(isRoomID && {ref:stickyChildRef})}
								>
									{name}
								</th>
							)})}
					</tr>
				)
			}}
			itemContent={(index, data) => {
				const portalUrl = `${process.env.REACT_APP_PORTAL_URL}/#/`;
				return (
						<>
						{Object.entries(headings).map((entry) => {
							const entryKey = entry?.[1]?.key;
							const postValue = data[entryKey];
							const dataType = getDataType(postValue);
							const isRoomID = entryKey === 'roomId';
							const isClaimID = entryKey === 'claimId';
							const failureReason = JSON.parse(postValue?.Cause || '{}')?.errorMessage;

							if (entryKey === 'actions') {
								return (
									<td style={{ display: 'flex', justifyContent: 'center', padding: 25 }}>
										<button onClick={() => handleDownload(data.roomId)} title="Download Room" className="btn btn-outline-secondary"><i className="bi bi-download" /></button>
										<button onClick={() => handleDownload(data.roomId, true)} title="Regenerate and Download Room" className="btn btn-outline-secondary"><i className="bi bi-hourglass" /></button>
									</td>
								)
							}
							if (entryKey === 'priority') {
								return (
									<td className="priority position-relative" align="center">
											<div className="table__body__badge" onClick={() => setPriority(data)}>
												<div>{data.priority}</div>
											</div>
									</td>
								)
							} else if (dataType === 'UUID') {
								return (
									<td className={classnames({
										'table__body__stuck-column': isSticky && isRoomID,
										'table__body__sticky': isRoomID,
										'table__body__white-column': isSticky && isRoomID,
									})}>
										<UUIDLink
											uuid={postValue}
											{
												...{
													...(isClaimID && {link: `${portalUrl}${process.env.REACT_APP_CLAIM_PREFIX}/${data.claimId}`}),
													...(isRoomID && {link: `${portalUrl}${getPrefix(data)}/${data.claimId}/${data.roomId}`}),
												}
											}
										/>
									</td>
								);
							} else if (entryKey === 'status') {
								return (
									<td className={data.status}>
										<div className="status-badge">{data.status}</div>
									</td>
								)
							} else if (dataType === 'Date' && entryKey !== 'address') {
								return (<td>{defaultDateFormat(postValue)}</td>)
							} else if (entryKey === 'notes') {
								return (
									<td className="comments">
										<button
											className="edit"
											onClick={(e) => handleComments(e, data)}
										>
											<p className="notesValue">
												{data.notes ? data.notes : ''}
											</p>
											<img
												src={EditIcon}
												alt="edit-icon"
												className="ms-auto"
											/>
										</button>
									</td>
								);
							} else if (entryKey.includes('duration') && dataType !== null) {
								return (
									<td>{formatDuration(postValue)}</td>
								)
							} else if (entryKey === 'email') {
								return <td style={{ minWidth : 200 }}>{postValue}</td>
							} else if (failureReason) {
								return (
									<td>
										<div style={{ display: "flex", justifyContent:"space-between"}}>
											<div style={{
												width: 200,
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow:'ellipsis'
											}}>
												{failureReason}
											</div>
											<div>
												<a role="button" className="text-secondary" onClick={() => copyToClipBoard(failureReason)}>
													<i className="bi bi-copy" />
												</a>
											</div>
										</div>
									</td>
								);
							}  else if (dataType === null) {
								return (<td>--</td>);
							}
							
							return (<td>{postValue}</td>);
						})}
					</>
				)
			}}
			rangeChanged={(range) => {
				loadMoreRows(range);
			}}
		/>
	);
};

export default TableBody;