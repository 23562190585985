import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { DropDownComponent } from "../DropDownComponent/DropDownComponent";
import { InputComponent } from "../InputComponent/InputComponent";
import CrossIcon from "../../../assets/images/icons/icon-cross.svg";

const FilterComponent = (props) => {
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    props.handleVisible();    
  }, [filter, props]);

  let Company = props.organisations;
  let Room_type = props.data.map((key) => key.roomType);
  Room_type = [...new Set(Room_type)];

  const handleradioSelect = (e) => {
    let { value, name } = e.target;
    if (
      filter.filter((fil) => fil["value"] === value && fil["type"] === name)
        .length
    ) {
      setFilter(
        filter.filter((fil) => fil["value"] !== value && fil["type"] !== name)
      );
    } else {
      setFilter([
        ...filter.filter((fil) => fil["type"] !== name),
        { type: name, value: value },
      ]);
    }
  };
  const handleInput = (e) => {
    let { value, name } = e.target;
    // if (
    //   filter.filter((fil) => fil["Type"] === name)
    //     .length
    // ) {
    //   setFilter([...filter.filter((fil) => fil["Type"] !== name), { Type: name, value: value }]);
    // } else {
      if (value.length > 0) {
        setFilter([
          ...filter.filter((fil) => fil["type"] !== name), { type: name, value: value }
        ]);
      }else{
        setFilter(filter.filter((fil) => fil["type"] !== name));
      }
    // }
  }; 
  const remove = (data) => {
    setFilter(filter.filter((item) => item["value"] !== data));
  };
  const Prority = [1, 0];
  const Status = ["Processing", "Initiated", "Failed", "Processed", "Aborted"];  

  var input = document.getElementById("accordiannn");
  input?.addEventListener("keypress", function(event) {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === "Enter") {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      props.filterData(filter);
    }
  });

  return (
    <>
      {filter && (
        <div className="filter px-2">
          {filter.filter((f) => !['roomId', 'LastEvaluatedKey'].includes(f.type)).map((data, index) => {
            return (
              <>
                <button type="button" className="btn btn-light filter-btn  m-1">
                  {data.value.length > 5
                    ? data.value.substring(0, 5) + "..."
                    : data.value}
                  <i>
                    <img
                      className="cross-img ms-1"
                      key={index}
                      src={CrossIcon}
                      alt="cross-icon"
                      onClick={() => remove(data.value)}
                    />
                  </i>
                </button>
              </>
            );
          })}
        </div>
      )}
      <Accordion flush id="accordiannn">
        <DropDownComponent
          eventKey="0"
          title="Prioritization"
          name="priority"
          data={Prority}
          filter={filter}
          handleradioSelect={handleradioSelect}
        />
        <DropDownComponent
          eventKey="1"
          title="Status"
          name="status"
          data={Status}
          filter={filter}
          handleradioSelect={handleradioSelect}
        />
        <DropDownComponent
          eventKey="2"
          title="Company Name"
          name="org"
          data={Company}
          filter={filter}
          handleradioSelect={handleradioSelect}
        />
        <DropDownComponent
          eventKey="3"
          title="Room Type"
          name="roomType"
          data={Room_type}
          filter={filter}
          handleradioSelect={handleradioSelect}
        />
        <InputComponent
          eventKey="4"
          title="Claim ID"
          name="claimId"
          handleInput={handleInput}
          filter={filter}
          remove={(data)=>setFilter(filter.filter((fil) => fil["value"] !== data))}
        />
        <InputComponent
          eventKey="5"
          title="Job ID"
          name="jobId"
          handleInput={handleInput}
          filter={filter}
          remove={(data)=>setFilter(filter.filter((fil) => fil["value"] !== data))}
        />
        <div className="popover__footer d-flex justify-content-between my-3 px-3">
          <button
            disabled={filter.length > 0 ? false : true}
            className="clear__filter"
            onClick={(e) => {props.cancleClick(e) }}
          >
            Clear all
          </button>
          <button
            disabled={filter.length > 0 ? false : true}
            className="apply__filter"
            onClick={(e)=>{
              e.preventDefault()
              props.filterData(filter);
            }}
          >
            Apply Filter
          </button>
        </div>
      </Accordion>
    </>
  );
};

export default FilterComponent;
